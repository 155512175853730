<template>
  <div class="home-wrapper mobile-search-page">
    <input
        placeholder="Хайх утгаа оруулна уу"
        v-model="query"
        class="m-search-input"
        @input="onInputChange"
    />
    <div class="mobile-search-result">
      <router-link v-for="item in items" :key="item.index" :to="`/watch/${item.id}/${item.epID}`" class="search-item">
        <img class="search-img" :src="'https://animaxplus.app/'+item.image"/>
        <span class="search-text">
          {{ item.title }}
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
import api from "../store/_boot/baseApi";
export default {
  data() {
    return {
      query: "",
      items: []
    };
  },

  created() {
  },

  methods: {
    onInputChange() {
      if (this.query == "") {
        this.items = [];
      } else {
        api.get(`/api/search?q=${this.query}`).then(response => {
          this.items = response.data;
        });
      }
    }
  }
};
</script>